export const TYPE_DELIVERY = [
  {
    label: 'FCL',
    value: 1,
  },
  {
    label: 'LCL',
    value: 2,
  },
];

export const CONTAINER_TYPE = [
  {
    label: '20ft',
    value: 1,
  },
  {
    label: '40ft',
    value: 2,
  },
  {
    label: '2x20ft',
    value: 3,
  },
];

export const TERM_DELIVERY = [
  {
    label: 'DTD',
    value: 1,
  },
  {
    label: 'DTP',
    value: 2,
  },
  {
    label: 'PTD',
    value: 3,
  },
  {
    label: 'PTP',
    value: 4,
  },
];

export const ROUTE_DELIVERY = [];

export const CLAIM_TOPIC = [
  {
    label: 'Barang Rusak',
    value: 1,
  },
  {
    label: 'Barang Hilang',
    value: 2,
  },
];

export const PICKUP_DETAIL_TYPE = [
  {
    label: 'Muat di Pelabuhan',
    value: 1,
  },
  {
    label: 'Muat di Gudang / Pabrik',
    value: 2,
  },
];

export const PAYMENT_DETAIL_TYPE = [
  {
    label: 'Asal',
    value: 1,
  },
  {
    label: 'Tujuan',
    value: 2,
  },
  {
    label: 'Lainnya',
    value: 3,
  },
];

export const UNLOAD_DETAIL_TYPE = [
  {
    label: 'Bongkar di Pelabuhan (SPPS)',
    value: 1,
  },
  {
    label: 'Bongkar di Alamat Tujuan (SP2)',
    value: 2,
  },
];

export const ORDER_STATUS = [
  {
    label: 'Menunggu Konfirmasi',
    value: 0,
  },
  {
    label: 'Barang Sedang Dijemput',
    value: 1,
  },
  {
    label: 'Barang Telah diterima Di Alamat Asal',
    value: 2,
  },
  {
    label: 'Barang Telah diterima Di Port Pusat',
    value: 3,
  },
  {
    label: 'Barang Telah berangkat Di Port Asal',
    value: 4,
  },
  {
    label: 'Barang Telah sampai Di Port Tujuan',
    value: 5,
  },
  {
    label: 'Barang Telah sampai Di Port Tujuan',
    value: 6,
  },
  {
    label: 'Barang Sedang Diantar ke alamat tujuan',
    value: 7,
  },
  {
    label: 'Selesai',
    value: 8,
  },
];

export const PAYMENT_STATUS = [
  {
    label: 'Belum bayar',
    value: 0,
  },
  {
    label: 'Sudah bayar',
    value: 1,
  },
  {
    label: 'Lunas termin',
    value: 2,
  },
  {
    label: 'Lunas',
    value: 3,
  },
];

export const PAYMENT_METHOD = [
  {
    label: 'Transfer',
    value: 1,
  },
  {
    label: 'Tunai',
    value: 2,
  },
];

export const TICKET_STATUS = [
  {
    label: 'Open',
    value: 0,
  },
  {
    label: 'Close',
    value: 1,
  },
];

export const CLAIM_STATUS = [
  {
    label: 'Menunggu Approval',
    value: 0,
  },
  {
    label: 'Dalam Proses',
    value: 1,
  },
  {
    label: 'Ditolak',
    value: 2,
  },
  {
    label: 'Disetujui',
    value: 3,
  },
  {
    label: 'Selesai',
    value: 4,
  },
];

export const VEHICLE_TYPES = {
  '20ft': 'total_20ft_container',
  '40ft': 'total_40ft_container',
  '2x20ft': 'total_combo_container',
};

//AUTH
export const REGISTER = 'v1/auth/register';
export const LOGIN = 'v1/auth/login';
export const PROFILE = 'v1/auth/profile';
export const REFRESH_TOKEN = 'v1/auth/refresh_token';
export const LOGOUT = 'v1/auth/logout';
export const FORGOT_PASSWORD = 'v1/auth/forgot_password';
export const RESET_PASSWORD = 'v1/auth/reset_password';
export const CHANGE_PASSWORD = 'v1/auth/change_password';
export const UPDATE_PROFILE = 'v1/auth/profile';

//ORDER
export const ORDER = 'v1/order';
export const ORDER_V2 = 'v2/order';

//ORDER
export const INVOICE = 'v1/admin/invoice';
export const INVOICE_EVIDENCE = 'v1/admin/invoice_evidence';

//CLAIM
export const CLAIM = 'v1/claim';

//TICKET
export const TICKET = 'v1/ticket';
export const ADMIN_TICKET = 'v1/admin/ticket';

//MISC
export const ROUTE = 'v1/route';
export const COMMODITY = 'v1/commodity';
export const SHIP = 'v1/ship';
export const SHIPING = 'v1/shipping';
export const CONTACT = 'v1/contact';
export const VEHICLE_TYPES = 'v1/admin/vehicle_type';
export const COSTUMER = 'v1/customer';
export const COSTUMER_SUGGESTIONS = 'v1/customer/suggestions';
export const DISTRICT = 'v1/admin/district';

import React from 'react';

import PropTypes from 'prop-types';

const FormObserver = ({values, onChange, startAtInit}) => {
  const isInitialize = React.useRef(false);

  const valueString = JSON.stringify(values);

  React.useEffect(() => {
    if (typeof onChange === 'function') {
      if (!isInitialize.current && !startAtInit) {
        isInitialize.current = true;
        return;
      }

      isInitialize.current = true;
      onChange(values);
    }
  }, [valueString]);

  return <></>;
};

export default FormObserver;

FormObserver.propTypes = {
  values: PropTypes.object,
  onChange: PropTypes.func,
  startAtInit: PropTypes.bool,
};

import React from 'react';

import {NavLink, useNavigate, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import {scroller, animateScroll as scroll} from 'react-scroll';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import InputBase from '@mui/material/InputBase';
import Container from '@mui/material/Container';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import {styled} from '@mui/material/styles';
import LanguageIcon from '@mui/icons-material/Language';
import Menu from '@mui/icons-material/Menu';
import Close from '@mui/icons-material/Close';
import {grey} from '@mui/material/colors';

import AppNavLink from '../../../AppNavLink';
import languageData from '../../../AppLngSwitcher/data';

import routeUrl from 'shared/constants/RouteUrl';

import IntlMessages from '../../../../utility/IntlMessages';
import {publicRoutesConfig} from '../../../../../pages/routesConfig';
import {useSidebarContext} from '../../../../utility/AppContextProvider/SidebarContextProvider';
import {
  useLocaleActionsContext,
  useLocaleContext,
} from '../../../../utility/AppContextProvider/LocaleContextProvide';

import Logo from '../../../../../assets/logo/unica-logo.png';
import {MenuItem, Select} from '@mui/material';
import {useAuthUser} from '@crema/utility/AuthHooks';

const BootstrapInput = styled(InputBase)(({theme}) => ({
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: 'none',
    fontSize: 12,
    color: theme.palette.text.primary,
    padding: '10px 26px 10px 8px',
    fontFamily: ['Poppins', 'sans-serif'].join(','),
    '&:focus': {
      boxShadow: 'none',
    },
  },
}));

const AppPublicHeaderDropdownMenu = () => {
  const renderItem = (item, index) => (
    <li key={`link-${index}`}>
      <ListItem
        component={AppNavLink}
        activeClassName='active'
        to={routeUrl.home}
        exact={item.exact}
        sx={{p: 0, py: 2, color: (theme) => theme.palette.grey[600]}}
      >
        <ListItemText
          sx={{
            '.MuiListItemText-primary': {
              fontSize: 14,
              lineHeight: 1,
              fontWeight: '500',
            },
          }}
          primary={<IntlMessages id={item.title} />}
        />
      </ListItem>
    </li>
  );

  return <List sx={{p: 0, pb: 2}}>{publicRoutesConfig.map(renderItem)}</List>;
};

const AppPublicHeaderButton = ({children, sx, ...props}) => {
  return (
    <Button
      {...props}
      sx={{
        p: {xs: '9px 12px', sm: '14px 16px'},
        boxShadow: 'none',
        borderRadius: 1,
        fontSize: 12,
        lineHeight: 1,
        minWidth: 0,
        mr: 2,
        ...sx,
      }}
    >
      {children}
    </Button>
  );
};

const AppPublicHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {locale} = useLocaleContext();
  const {updateLocale} = useLocaleActionsContext();
  const {sidebarMenuSelectedTextColor} = useSidebarContext();
  const {isAuthenticated} = useAuthUser();

  const [showMenu, setShowMenu] = React.useState(false);

  const passingDestination = location.state?.destination ?? null;

  React.useEffect(() => {
    if (passingDestination) {
      scroller.scrollTo(passingDestination, {
        duration: 500,
        smooth: true,
        offset: -60,
      });
    } else {
      scroll.scrollToTop({duration: 0});
    }
  }, [passingDestination]);

  const handleLanguageChange = (value) => (event) => {
    let newLang = value;

    if (
      event.target.value !== undefined &&
      typeof event.target.value === 'number'
    ) {
      newLang = languageData[event.target.value];
    }

    // console.log(newLang, value, event.target.value);
    updateLocale(newLang);
  };

  const handleDropdownMenuShow = () => {
    setShowMenu((prev) => !prev);
  };

  const handleOnLinkPress = ({destination}) => {
    return () => {
      if (window.location.pathname !== '/') {
        navigate(routeUrl.home, {
          state: {destination},
        });
        return;
      }

      scroller.scrollTo(destination, {
        duration: 500,
        smooth: true,
        offset: -60,
      });
    };
  };

  const renderItem = (item, index) => (
    <ListItem
      key={`link-${index}`}
      onClick={handleOnLinkPress(item)}
      sx={{
        minHeight: 34,
        px: 5,
        py: 0,
        cursor: 'pointer',
        color: (theme) => theme.palette.text.primary,
        textDecoration: 'none!important',
        '&.active': {
          color: sidebarMenuSelectedTextColor + '!important',
          '& .list-item-text-primary': {
            color: 'inherit',
          },
          '& .list-item-icon': {
            color: 'inherit',
          },
        },
      }}
    >
      <ListItemText
        sx={{'.MuiListItemText-primary': {fontSize: 12}}}
        primary={<IntlMessages id={item.title} />}
      />
    </ListItem>
  );

  const renderLangOptionsBtn = (item, index) => {
    return (
      <AppPublicHeaderButton
        key={`lang-btn-${index}`}
        onClick={handleLanguageChange(item)}
        variant='outlined'
        color={item.locale === locale.locale ? 'primary' : 'inactive'}
      >
        {(item?.locale ?? '').toUpperCase()}
      </AppPublicHeaderButton>
    );
  };

  const renderLangOptionsList = (item, index) => {
    return (
      <MenuItem value={index} key={`lang-btn-${index}`}>
        {(item?.locale ?? '').toUpperCase()}
      </MenuItem>
    );
  };

  return (
    <Box
      sx={{
        boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.05)',
        position: 'fixed',
        top: 0,
        right: 0,
        left: 0,
        zIndex: 999,
        px: {xs: 3, lg: 0},
        background: (theme) => theme.palette.background.paper,
      }}
    >
      <Container>
        <Box sx={{display: 'flex', alignItems: 'center', py: 2.5}}>
          <NavLink to={routeUrl.home} style={{marginRight: 16}}>
            <Box sx={{height: {xs: 30, sm: 40}}}>
              <img src={Logo} style={{maxHeight: '100%'}} />
            </Box>
          </NavLink>
          <List
            className='navbarNav'
            sx={{py: 1.25, display: {xs: 'none', sm: 'flex'}}}
          >
            {publicRoutesConfig.map(renderItem)}
          </List>

          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {isAuthenticated ? (
              <NavLink to={routeUrl.dashboard} style={{marginRight: 12}}>
                <AppPublicHeaderButton variant='contained' color='secondary'>
                  <IntlMessages id='sidebar.menu.dashboard' />
                </AppPublicHeaderButton>
              </NavLink>
            ) : (
              <>
                <NavLink to={routeUrl.signin} style={{marginRight: 12}}>
                  <AppPublicHeaderButton color='secondary'>
                    <IntlMessages id='common.login' />
                  </AppPublicHeaderButton>
                </NavLink>
                <NavLink to={routeUrl.signup} style={{marginRight: 12}}>
                  <AppPublicHeaderButton variant='contained' color='secondary'>
                    <IntlMessages id='common.signup' />
                  </AppPublicHeaderButton>
                </NavLink>
              </>
            )}
          </Box>

          <Box
            sx={{
              display: {xs: 'none', sm: 'flex'},
              alignItems: 'center',
              ml: 2,
            }}
          >
            <LanguageIcon sx={{fontSize: 20}} />
            <Select
              value={languageData
                .map(({locale}) => locale)
                .indexOf(locale.locale)}
              onChange={handleLanguageChange()}
              input={<BootstrapInput />}
            >
              {languageData.map(renderLangOptionsList)}
            </Select>
          </Box>

          <IconButton
            sx={{p: 0, display: {xs: 'inline-flex', sm: 'none'}, color: '#000'}}
            aria-label='toggle menu visibility'
            onClick={handleDropdownMenuShow}
          >
            {showMenu ? <Close /> : <Menu />}
          </IconButton>
        </Box>

        {showMenu && (
          <Box
            sx={{
              pt: 3,
              pb: 6,
              borderTop: `1px solid ${grey[300]}`,
              display: {xs: 'block', sm: 'none'},
            }}
          >
            <AppPublicHeaderDropdownMenu />

            {languageData.map(renderLangOptionsBtn)}
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default AppPublicHeader;

AppPublicHeader.propTypes = {};

AppPublicHeaderButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  sx: PropTypes.object,
};
AppPublicHeaderDropdownMenu.propTypes = {};
